<template>
    <div class="py-3">
        <div class="fl-x fl-j-c">
            <img src="../../../assets/web/emd-submissioon/pdf.png" alt="" class="mb-4">
        </div>

        <div class="text-center mb-4">
            <btn text="Download Bid Form" shadow="bs-5" @click="downloadEmdForm"
                 class="c-secondary-bg bs-custom fs-lg-2 download-btn c-secondary-border" size="lg"></btn>
        </div>

        <div class="px-lg-4">
            <span class="font-inter-semi-bold"><span class="text-danger">*</span>Note :</span>

            <span class="mb-3 font-inter-medium"> Download and fill up this Bid form and submit it to the nearest Bank (Bank Details) and submit
                EMD amount.
            </span>

            <p class="mt-3 font-inter-medium">Upload verified Bid form and payment receipt via upload button given
                below</p>

            <btn text="Upload Bid Form" class="c-secondary-bg fs-lg-2 btn-icon-chevron-right-lg c-secondary-border mb-3"
                 size="lg" block @click="setUploadForm"></btn>

            <btn text="Close" @click="setClose" design="basic-b" class="c-secondary-border fs-lg-2" block
                 size="lg"></btn>
        </div>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name  : 'EmdDownload',
    props : {
        id : {
            type : [String, Number]
        }
    },

    data () {
        return {
            participantEmdFormDownloadUrl : ''
        };
    },

    beforeMount () {
        this.setEmdData();
    },

    methods : {
        setEmdData () {
            const that = this;
            axios.get(urls.auction.participantEmdFormDownload, { params : { id : that.id } })
                .then(function (response) {
                    const json = response.data;
                    if (json.error === false) {
                        that.participantEmdFormDownloadUrl = json.document;
                        that.loading = false;
                    } else {
                        that.loading = false;
                        that.error = true;
                    }
                }).catch(function (exception) {
                    console.log('exception at the incident details loading => ', exception);
                    that.loading = false;
                    that.error = true;
                });
        },

        downloadEmdForm () {
            if (this.participantEmdFormDownloadUrl) {
                window.open(this.participantEmdFormDownloadUrl);
            } else {
                this.$notify('Could not download the participant Bid from, Please try again later', 'Failed', {
                    type : 'danger'
                });
            }
        },
        setClose () {
            this.$emit('closeModal');
        },

        setUploadForm () {
            this.$emit('openForm');
        }
    }
};
</script>

<style scoped>

</style>
