<template>
    <div>
        <custom-vue-table-card class="c-vue-table-card d-sm-none-lg-block" :fields="fields" :url="listUrl"
                               :per-page="10"
                               ref="table">

            <template #buttons>
                <validated-input placeholder="Search Bidder ID">
                    <template #append>
                        <i class="fa fa-search"></i>
                    </template>
                </validated-input>
            </template>

            <template #participant_id="{rowData}">
                <btn shadow="0" class="text-decoration-none line-bottom-primary" design=""
                     @click="viewBidderDetails(rowData)">{{ rowData.participant_id }}
                </btn>
            </template>

            <template #is_bank_admin="{rowData}">
                <span v-if="rowData.is_bank_admin">Bank Admin</span>
                <span v-else>Customer</span>
            </template>

            <template #attachments="{rowData}">
                <btn shadow="0" class="text-decoration-none line-bottom-primary" design=""
                     @click="showAttachment(rowData)">View
                </btn>
            </template>

        </custom-vue-table-card>
        <emd-rejected-mobile-view @viewBidderDetails="viewBidderDetails" class="d-lg-none"
                                  @view-attachment="showAttachment"></emd-rejected-mobile-view>
    </div>
</template>

<script>
import urls from '@/data/urls';
import EmdRejectedMobileView from '../../views/manage-submitted-emd/mobile-view/EmdRejectedMobileView';

export default {
    name       : 'SubmittedEmdRejected',
    components : { EmdRejectedMobileView },
    data () {
        return {
            listUrl : urls.auction.participant.emdFormList + '?auction=' + this.$route.params.id + '&status=failure',

            fields : [
                {
                    name  : '__slot:participant_id',
                    title : 'Bidder ID'
                },
                {
                    name  : 'utr_number',
                    title : 'Payment Reference Number'
                },
                {
                    name  : 'amount',
                    title : 'Amount'
                },
                {
                    name  : 'submitted_date',
                    title : 'Submitted Date'
                },
                {
                    name  : '__slot:attachments',
                    title : 'Attachment'
                },
                {
                    name  : 'rejected_date',
                    title : 'Rejected Date'
                },
                {
                    name  : 'rejected_by',
                    title : 'Rejected By'
                },
                {
                    name  : 'rejected_reason',
                    title : 'Rejected Reason'
                },
                {
                    name  : 'added_by',
                    title : 'Added By'
                }
            ]
        };
    },

    methods : {

        viewBidderDetails (data) {
            this.$emit('viewBidderDetails', data);
        },

        showAttachment (data) {
            this.$emit('showAttachment', data);
        }
    }
};
</script>

<style scoped>

</style>
