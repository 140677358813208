<template>
    <div class="upload-emd-form-height-resp of-a">
        <b-form class="px-4 pb-4" :save-url="addUrl" @error="formFailure" @success="formSuccess"
                v-slot="{model, loading}" :save-params="{auction: $route.params.id, is_bank_admin : true}">
            <div class="row">
                <div class="mb-2 col-12 col-md-12">
                    <validated-input @input="resetParticipant" label="Participant*"
                                     placeholder="Enter participant E-Mail"
                                     v-model="model.username" type="email"
                                     name="Participant" :disabled="loading"
                                     class="pos-r  size-lg mb-0">
                        <div class="pos-abs-l-0">abc</div>
                        <template #append>
                            <btn :disabled="validating" type="button" :loading="validating" loading-text="Searching..."
                                 @click="validateParticipant(model.username)" class="c-secondary-bg" text="Search"/>
                        </template>
                    </validated-input>
                    <span v-if="errorMessage" class="p-0 m-0 text-danger">Invalid Participant</span>
                </div>
                <div class="col-12 col-md-12">
                    <validated-input v-if="participantDetails.name" class="input-size-lg mb-4"
                                     :class="{'c-premium-form-input' : userType === 'premium'}"
                                     v-model="participantDetails.name" :disabled="true" name="Participant Name"
                                     label="Participant Name"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">

                    <validated-input class="input-size-lg mb-4"
                                     :class="{'c-premium-form-input' : userType === 'premium'}"
                                     placeholder="Enter Payment Reference Number*"
                                     v-model="model.utr_number" :disabled="loading" name="Payment Reference Number"
                                     label="Payment Reference Number*"/>
                </div>
                <div class="col-12 col-md-6">

                    <validated-file-input label="Upload Bid form" placeholder="Upload Bid form"
                                          v-model="model.emd_payment_form"
                                          name="Upload Bid form" :disabled="loading"
                                          class="pos-r c-file-input  size-lg mb-4">
                        <div class="pos-abs-l-0">abc</div>
                        <template #append>
                            <div
                                class="text-white pointer-events-none c-secondary-bg pos-a-r-0 h-100 c-px fl-x-cc upload-arrow-right">
                                Upload
                            </div>
                        </template>
                    </validated-file-input>
                </div>
                <div class="col-12 col-md-6">

                    <validated-file-input label="Upload EMD Payment Receipt" placeholder="Upload EMD Payment Receipt"
                                          v-model="model.emd_payment_receipt" name="Upload EMD Payment Receipt"
                                          :disabled="loading" class="pos-r mb-4 c-file-input size-lg">
                        <div class="pos-abs-l-0">abc</div>
                        <template #append>
                            <div
                                class="text-white pointer-events-none c-secondary-bg pos-a-r-0 h-100 c-px fl-x-cc upload-arrow-right">
                                Upload
                            </div>
                        </template>
                    </validated-file-input>
                </div>
                <div class="col-12 col-md-6">

                    <validated-file-input label="Proof Of Address" placeholder="Upload Proof Of Address"
                                          v-model="model.proof_of_address" name="Upload EMD Payment Receipt"
                                          :disabled="loading" class="pos-r mb-4 c-file-input size-lg">
                        <div class="pos-abs-l-0">abc</div>
                        <template #append>
                            <div
                                class="text-white pointer-events-none c-secondary-bg pos-a-r-0 h-100 c-px fl-x-cc upload-arrow-right">
                                Upload
                            </div>
                        </template>
                    </validated-file-input>
                </div>
                <div class="col-12 col-md-6">

                    <validated-file-input label="Pancard" placeholder="Upload Pancard"
                                          v-model="model.pancard" name="Upload Pancard"
                                          :disabled="loading" class="pos-r mb-4 c-file-input size-lg">
                        <div class="pos-abs-l-0">abc</div>
                        <template #append>
                            <div
                                class="text-white pointer-events-none c-secondary-bg pos-a-r-0 h-100 c-px fl-x-cc upload-arrow-right">
                                Upload
                            </div>
                        </template>
                    </validated-file-input>
                </div>
                <div class="col-12 col-md-6">

                    <validated-file-input label="Declaration Form" placeholder="Upload Declaration Form"
                                          v-model="model.declaration_form" name="Upload Declaration Form"
                                          :disabled="loading" class="pos-r mb-4 c-file-input size-lg">
                        <div class="pos-abs-l-0">abc</div>
                        <template #append>
                            <div
                                class="text-white pointer-events-none c-secondary-bg pos-a-r-0 h-100 c-px fl-x-cc upload-arrow-right">
                                Upload
                            </div>
                        </template>
                    </validated-file-input>
                </div>
                <div class="col-12 col-md-6">
                    <validated-input class="input-size-lg mb-4"
                                     :class="{'c-premium-form-input' : userType === 'premium'}"
                                     placeholder="Refund Account Number*" :rules="{required:true}"
                                     v-model="model.account_number" :disabled="loading" name="Refund Account Number"
                                     label="Refund Account Number*"/>
                </div>
                <div class="col-12 col-md-6">
                    <validated-input class="input-size-lg mb-4"
                                     :class="{'c-premium-form-input' : userType === 'premium'}"
                                     placeholder="Refund Account Name*" :rules="{required:true}"
                                     v-model="model.account_name" :disabled="loading" name="Refund Account Name"
                                     label="Refund Account Name*"/>
                </div>
                <div class="col-12 col-md-6">
                    <validated-input class="input-size-lg mb-4"
                                     :class="{'c-premium-form-input' : userType === 'premium'}"
                                     placeholder="IFSC Code*" :rules="{required:true}"
                                     v-model="model.ifsc_code" :disabled="loading" name="IFSC Code"
                                     label="IFSC Code*"/>
                </div>
                <div class="col-12">
                    <div class="fl-x-res px-md-8 fl-a-c mt-md-3">
                        <btn text="Submit" class="c-secondary-bg fs-lg-2 c-secondary-border mr-md-3 mb-3 mb-md-0"
                             size="lg" :disabled="loading || !is_valid_participant" block :loading="loading"
                             loading-text="Saving..."></btn>

                        <btn text="Close" type="button" :disabled="loading" @click="setClose"
                             design="basic-b" class="c-secondary-border fs-lg-2" block size="lg"></btn>
                    </div>
                </div>
            </div>

        </b-form>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name  : 'UploadEmd',
    props : {
        userType : {
            type    : String,
            default : 'normal'
        }
    },

    data () {
        return {
            errorMessage         : false,
            validating           : false,
            is_valid_participant : false,
            participantDetails   : {},
            addUrl               : urls.auction.updateParticipantEmd
        };
    },

    methods : {

        setClose () {
            this.$emit('closeModal');
        },

        formSuccess () {
            this.$emit('success');
        },

        formFailure () {
            this.$emit('failure');
        },
        async validateParticipant (username) {
            this.validating = true;
            this.resetParticipant();
            const response = await axios.form(urls.auction.participantFetch,
                { username : username });
            const json = response.data;
            if (json.error === false) {
                this.is_valid_participant = true;
                this.participantDetails = json.data;
            } else {
                this.errorMessage = true;
                this.$notify('Please try again', 'Error', {
                    type : 'danger'
                });
            }
            this.validating = false;
        },
        resetParticipant () {
            this.is_valid_participant = false;
            this.participantDetails = {};
            this.errorMessage = false;
        }
    }
};
</script>

<style scoped lang="scss">
.pos-a-r-0 {
    position: absolute;
    right: 0;
}

.c-px {
    padding: 0 2.5rem;
}

.pos-abs-l-0 {
    position: absolute;
    left: 0;
}
    .upload-emd-form-height-resp{
        max-height: 70vh;
        @media (min-width: 992px) {
            max-height: unset;
        }
    }
</style>
