export default {
    noCloseOnBackdrop : { type : Boolean, default : false },
    noCloseOnEsc      : { type : Boolean, default : false },

    width          : { type : String, default : '50r' },
    title          : { type : String, default : 'Title' },
    enterAnimation : { type : String, default : 'zoomIn' },
    exitAnimation  : { type : String, default : 'zoomOut' },

    dialog         : { type : Boolean, default : false },
    isCustomDialog : { type : Boolean, default : false },
    okButton       : { type : String, default : 'Ok' },
    cancelButton   : { type : String, default : 'Cancel' },

    okButtonColor     : { type : String, default : 'success' },
    cancelButtonColor : { type : String, default : 'danger' },

    okButtonDesign     : { type : String, default : 'basic' },
    cancelButtonDesign : { type : String, default : 'basic-b' },

    color       : { type : String, default : '2' },
    headerColor : { type : String, default : '' },
    footerColor : { type : String, default : '' },
    bodyColor   : { type : String, default : '' },

    showAdditionalButtons : { type : Boolean, default : false }
};
