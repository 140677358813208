<template>
    <b-form class="" :save-url="addUrl" @success="formSuccess" @failure="formFailed" v-slot="{model, loading}"
            :save-params="{id: details.id, status: 'Reject'}">

        <div class="fl-x fl-j-c mb-4">
            <img src="../../../assets/web/emd-submissioon/not-submitted.png" class="error-icon" alt="">
        </div>

        <h4 class="text-center font-inter-semi-bold mb-5">EMD Rejection</h4>

        <p class="text-5 text-left">*Please enter the reason behind EMD Rejection</p>

        <validated-text-area class="text-4" color="secondary" :rules="{required: true}" name="Reason"
                             label="" v-model="model.rejected_reason" :disabled="loading">
            <template #append>
                <span><i class="fa fa-check-circle fs-lg-5 text-success"></i></span>
            </template>
        </validated-text-area>

        <div class="mt-5">
            <btn size="md" block text="Submit" class="py-3 bg-secondary-600" border-radius="1"
                 :disabled="loading" :loading="loading" loading-text="Saving..."/>

            <btn size="md" block class="mt-3 btn-basic-b py-3 bg-hover-secondary-600" border-radius="1"
                 :disabled="loading" @click="closeModal" text="Close" type="button"/>
        </div>
    </b-form>

</template>

<script>
import urls from '@/data/urls';

export default {
    name : 'EmdRejectModal',

    props : {
        details : {
            type : Object
        }
    },

    data () {
        return {
            addUrl : urls.auction.participant.changeStatusView
        };
    },

    methods : {
        formSuccess () {
            this.$emit('success');
        },

        formFailed () {
            this.$notify('Failed to reject the participant, Try again later..!', 'Error',
                { type : 'danger' }
            );
        },

        closeModal () {
            this.$emit('closeModal');
        }
    }
};
</script>

<style scoped>
.error-icon {
    height: 6rem;
}
</style>
