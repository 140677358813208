<template>
    <div class="px-lg-4 py-4 pt-6">
        <div class="fl-x fl-j-c">
            <img src="../../../assets/web/emd-submissioon/submitted.png" class="error-icon" alt="">
        </div>

        <p class="text-center fs-6 font-inter-bold mt-4" v-if="status === 'Accept'">EMD has been approved !</p>
        <p class="text-center fs-6 font-inter-bold mt-4" v-else>EMD has been rejected !</p>

        <p class="mb-7 text-center" v-if="status === 'Accept'">
            EMD form and payment receipt for Bidder id {{ details.participant_id }} has been submitted.
        </p>

        <p class="mb-7 text-center" v-else>
            EMD form and payment receipt for Bidder id {{ details.participant_id }} has been Rejected.
        </p>

        <btn text="Close" class="c-secondary-bg fs-lg-2 c-secondary-border mb-3"
             size="lg" block @click="closeModal"></btn>
    </div>
</template>

<script>

export default {
    name : 'EmdSubmittedSuccess',

    props : {
        details : {
            type : Object
        },

        status : {
            type : String
        }
    },

    methods : {
        closeModal () {
            this.$emit('closeModal');
        }
    }
};
</script>

<style scoped>
.error-icon {
    height: 6rem;
}
</style>
