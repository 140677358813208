<template>
    <div>
        <custom-vue-table-card class="c-vue-table-card d-sm-none-lg-block" :fields="fields" :url="listUrl"
                               :per-page="10" @pagination-updated="setTotal"
                               ref="table" :show-search-box="true">
            <template #buttons
                      v-if="currentUser.is_bank_admin || currentUser.is_ventura_admin
                       || currentUser.is_superuser || currentUser.permission.can_add_participant">
                <btn text="Add Participant" class="btn-primary" :disabled="participate_time_exceeded"
                     @click="setParticipate"/>
            </template>

            <template #attachments="{rowData}">
                <btn shadow="0" class="text-decoration-none line-bottom-primary" design=""
                     @click="showAttachment(rowData)">View
                </btn>
            </template>

            <template #participant_id="{rowData}">
                <btn shadow="0" class="text-decoration-none line-bottom-primary" design=""
                     @click="viewBidderDetails(rowData)">{{ rowData.participant_id }}
                </btn>
            </template>

            <template #is_bank_admin="{rowData}">
                <span v-if="rowData.is_bank_admin">Bank Admin</span>
                <span v-else>Customer</span>
            </template>

            <template #actions="{rowData}">
                <div class="">
                    <btn v-if="currentUser.is_superuser || currentUser.is_ventura_admin || currentUser.is_bank_admin ||
                                 currentUser.permission.can_approve_emd"
                         text="Accept" :loading="rowData.accepting" loading-text="Saving..."
                         @click="acceptEMDForm(rowData)"
                         :disabled="!showAcceptButton"
                         class="m-1" color="secondary"></btn>
                    <btn v-if="currentUser.is_superuser || currentUser.is_ventura_admin || currentUser.is_bank_admin ||
                                 currentUser.permission.can_reject_emd" text="Reject" :disabled="!showRejectButton"
                         color="danger" class="m-1"
                         @click="setReject(rowData)"></btn>
                </div>
            </template>

        </custom-vue-table-card>

        <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="rejectModal" width="30r"
               header-color="">
            <emd-reject-modal :details="auction" @closeModal="closeModal"
                              @success="formSuccess" @failure="formFailed"></emd-reject-modal>
        </modal>

        <custom-modal class="c-modal-1 header-none bg-layer no-close" :no-close-on-backdrop="false"
                      title="EMD Submission"
                      ref="EMDSubmissionSuccessModal" width="30r"
                      header-color="">
            <emd-submitted-success :status="status" :details="auction"
                                   @closeModal="closeModal"></emd-submitted-success>
        </custom-modal>

        <!--    Add Participant Modals    -->

        <custom-modal class="c-modal-1 no-padding header-none bg-layer no-close" :no-close-on-backdrop="true" title=""
                      ref="EMDPaymentInstructionModal" width="30r"
                      header-color="">
            <template #header-area>
                <div class="text-white bg-blue-layer c-margin-x">
                    <p>You should complete the EMD payment procedures to participate in this auction</p>
                </div>
            </template>

            <div class="text-white bg-primary bg-blue-layer c-margin-x of-h fl-x-cc p-2 py-3">
                <img src="../../assets/web/emd-submissioon/emd-alerts.png" class="icon-md mr-3" alt="">
                <p class="m-0">You should complete the EMD payment procedures to participate in this auction</p>
            </div>

            <div class="text-center p-3">
                <p class="font-inter-semi-bold fs-lg-4">EMD Payment Instructions</p>
                <p class="text-7 fs-lg-9 font-inter-bold mb-3">01</p>
                <p class="font-inter-medium mb-2">Download Bid form from next tab</p>
                <p class="text-7 fs-lg-9 font-inter-bold mb-3">02</p>
                <p class="font-inter-medium mb-2">Fill up the Bid form and submit it to the nearest Bank(Bank Details)
                    and
                    submit EMD amount</p>
                <p class="text-7 fs-lg-9 font-inter-bold mb-3">03</p>
                <p class="font-inter-medium mb-4">Upload verified Bid form and payment receipt(Upload option will come
                    in the
                    next tab</p>

                <div class="px-lg-5">
                    <btn text="Continue" @click="setDownloadEMD" size="lg" block
                         class="bg-10 c-secondary-bg c-secondary-border"></btn>

                    <btn text="Close" size="lg" @click="$refs.EMDPaymentInstructionModal.close()" design="basic-b" block
                         class="bg-10 mt-3 c-secondary-border"></btn>
                </div>

            </div>
        </custom-modal>

        <custom-modal class="c-modal-1 bg-layer no-close" :no-close-on-backdrop="true" title="Bid Form"
                      ref="EMDDownloadModal" width="30r"
                      header-color="">
            <emd-download :id="id" @openForm="setUploadForm"
                          @closeModal="$refs.EMDDownloadModal.close();"></emd-download>
        </custom-modal>

        <custom-modal class="c-modal-1 bg-layer no-close" :no-close-on-backdrop="true" title="Bid Form Upload"
                      ref="EMDUploadModal" width="50r"
                      header-color="">
            <upload-emd :user-type="userType" @closeModal="$refs.EMDUploadModal.close();" @success="emdFormSuccess"
                        @failure="emdFormFailure"/>
        </custom-modal>

        <!--    Add Participant Modals End    -->

        <emd-waiting-mobile-view ref="emdWaitingMobileView" class="d-lg-none mt-3" @view-attachment="showAttachment"
                                 @viewBidderDetails="viewBidderDetails"
                                 @acceptEMDForm="acceptEMDForm" @setReject="setReject" :data="this.data"
                                 @set-participate="setParticipate">
        </emd-waiting-mobile-view>
    </div>
</template>

<script>
import CustomModal from '@components/lego-custom/modal/CustomModal';
import EmdSubmittedSuccess from '../../views/manage-submitted-emd/modals/EmdSubmittedSuccess';
import EmdRejectModal from '../../views/manage-submitted-emd/modals/EmdRejectModal';
import urls from '@/data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';
import EmdWaitingMobileView from '../../views/manage-submitted-emd/mobile-view/EmdWaitingMobileView';
import UploadEmd from './emd/UploadEmd';
import EmdDownload from './emd/EmdDownload';

export default {
    name : 'SubmittedEmdWaiting',

    components : {
        EmdDownload,
        UploadEmd,
        EmdWaitingMobileView,
        EmdRejectModal,
        EmdSubmittedSuccess,
        CustomModal
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    props : {
        data : {
            type : Object
        }
    },

    data () {
        return {
            auction  : null,
            userType : 'normal',
            id       : this.$route.params.id,
            status   : '',

            listUrl : urls.auction.participant.emdFormList + '?auction=' + this.$route.params.id + '&status=pending',

            fields : [
                {
                    name  : '__slot:participant_id',
                    title : 'Bidder ID'
                },
                {
                    name  : 'utr_number',
                    title : 'Payment Reference Number'
                },
                {
                    name  : 'amount',
                    title : 'Amount'
                },
                {
                    name  : 'submitted_date',
                    title : 'Submitted Date'
                },
                {
                    name  : '__slot:attachments',
                    title : 'Attachment'
                },
                {
                    name  : 'added_by',
                    title : 'Added By'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ],

            auction_start_date        : '',
            auction_start_time        : '',
            participate_time_exceeded : true,
            interval                  : null,
            total                     : '',
            showAcceptButton          : false,
            showRejectButton          : false
        };
    },

    beforeMount () {
        this.haveAcceptPermission();
        this.haveRejectPermission();
        this.loadData();
    },

    mounted () {
        this.setPremiumUser();
        this.interval = setInterval(() => {
            this.checkAuctionDateTime();
        }, 1000);
    },
    beforeDestroy () {
        clearInterval(this.interval);
    },

    methods : {
        setTotal (data) {
            this.total = data.total;
            this.$emit('updateCount', this.total, '0');
        },
        loadData () {
            const that = this;
            that.dataLoading = true;
            that.error = false;
            const model = {
                auction : that.$route.params.id
            };
            axios.form(urls.auction.auction.info, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.auction_start_date = json.data.auction_details.auction_start_date;
                    that.auction_start_time = json.data.auction_details.auction_start_time;
                    that.dataLoading = false;
                } else {
                    that.dataLoading = false;
                    that.error = true;
                }
            }).catch(function (exception) {
                console.log('exception at the incident details loading => ', exception);
                that.dataLoading = false;
                that.error = true;
            });
        },

        showAttachment (data) {
            this.$emit('showAttachment', data);
        },

        viewBidderDetails (data) {
            this.$emit('viewBidderDetails', data);
        },

        acceptEMDForm (rowData) {
            this.auction = { ...rowData };
            rowData.accepting = true;
            const that = this;

            const model = {
                id     : rowData.id,
                status : 'Accept'
            };

            that.status = 'Accept';

            axios.form(urls.auction.participant.changeStatusView, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    rowData.accepting = false;
                    that.status = 'Accept';
                    that.$refs.table.refreshTable();
                    that.$emit('updateCount', that.total, '1');
                    that.$refs.EMDSubmissionSuccessModal.show();
                } else {
                    rowData.accepting = false;
                    let message = 'Could not accept the participant, Please try again later.';
                    if (json.message) {
                        message = json.message;
                    }
                    that.$notify(message, 'Failed',
                        { type : 'danger' }
                    );
                    that.$refs.table.refreshTable();
                    that.$refs.emdWaitingMobileView.loadData();
                }
            }).catch(function (exception) {
                rowData.accepting = false;
                console.log('exception : ', exception);
            });
        },

        setReject (rowData) {
            this.auction = { ...rowData };
            this.$refs.rejectModal.show();
        },

        formSuccess () {
            this.$refs.table.refreshTable();
            this.$emit('updateCount', this.total, '2');
            this.status = 'Reject';
            this.$refs.EMDSubmissionSuccessModal.show();
        },

        formFailed () {
            this.$notify('Failed to reject the participant, Try again later..!', 'Error',
                { type : 'danger' }
            );
            this.$refs.table.refreshTable();
            this.$refs.emdWaitingMobileView.loadData();
        },

        closeModal () {
            this.$refs.EMDSubmissionSuccessModal.close();
            this.$refs.rejectModal.close();
            this.$refs.table.refreshTable();
            this.$refs.emdWaitingMobileView.loadData();
        },

        haveAcceptPermission () {
            console.log('this.currentUser.permission.can_approve_emd', this.currentUser.permission.can_approve_emd);
            if (this.currentUser.is_superuser || this.currentUser.is_bank_admin || this.currentUser.permission.can_approve_emd) {
                this.showAcceptButton = this.data.data.status !== 'Ended';
            } else {
                return false;
            }
        },

        haveRejectPermission () {
            console.log('this.currentUser.permission.can_reject_emd', this.currentUser.permission.can_reject_emd);
            if (this.currentUser.is_superuser || this.currentUser.is_bank_admin || this.currentUser.permission.can_reject_emd) {
                this.showRejectButton = this.data.data.status !== 'Ended';
            } else {
                return false;
            }
        },

        emitDataCount (count) {
            this.$emit('data-count', count);
        },

        setPremiumUser () {
            if (this.currentUser.is_premium_customer) {
                this.userType = 'premium';
            }
        },

        //    Add Participant Functions

        setParticipate () {
            this.$refs.EMDPaymentInstructionModal.show();
        },

        isValidDate (dateString) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const auctionStartDate = new Date(dateString);
            auctionStartDate.setHours(0, 0, 0, 0);
            return auctionStartDate >= today;
        },

        isValidTime (dateString, timeString) {
            const [hours, minutes] = timeString.split(':');
            // const auctionStartTime = new Date();
            const auctionStartTime = new Date(dateString);
            auctionStartTime.setHours(parseInt(hours));
            auctionStartTime.setMinutes(parseInt(minutes));
            const currentDateTime = new Date();
            const timeDifference = auctionStartTime - currentDateTime;
            const timeDifferenceInMin = timeDifference / 60000;
            return timeDifferenceInMin > 30;
        },

        checkAuctionDateTime () {
            if (this.auction_start_date && this.auction_start_time) {
                const isValidAuctionDate = this.isValidDate(this.auction_start_date);
                const isValidAuctionTime = this.isValidTime(this.auction_start_date, this.auction_start_time);
                this.participate_time_exceeded = !(isValidAuctionDate && isValidAuctionTime);
            }
        },

        setDownloadEMD () {
            this.$refs.EMDPaymentInstructionModal.close();
            this.$refs.EMDDownloadModal.show();
        },

        setUploadForm () {
            this.$refs.EMDDownloadModal.close();
            this.$refs.EMDUploadModal.show();
        },

        emdFormSuccess () {
            this.$refs.EMDUploadModal.close();
            this.$notify('Successfully added Participant ..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$refs.table.refreshTable();
            this.$emit('updateCount', this.total, '0');
            this.$refs.emdWaitingMobileView.loadData();
        },

        emdFormFailure () {
            this.$refs.EMDUploadModal.close();
            this.$notify('Failed to add Participant ..!', 'Error',
                {
                    type : 'danger'
                }
            );
            this.$refs.table.refreshTable();
            this.$refs.emdWaitingMobileView.loadData();
        },

        setDownload (url) {
            window.open(url);
        }

        //    Add Participant Functions End
    }
}
;
</script>

<style scoped>

</style>
