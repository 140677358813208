<template>
    <inner-page-card v-if="auction"
                     :heading="'Auction ID : ' + auction.auction_details.id +' - ' + 'Manage Participants'"
                     :link-back="true" :horz-padding="false">

        <div class="container" v-if="loading">
            <div class="card bg-info">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div class="container" v-else-if="error">
            <div class="card bg-danger fl-te-c">
                <div>
                    <error-icon/>
                    Failed to load data from the server.
                </div>
                <div>
                    <a class="pointer" @click="loadData()"><i class="fa fa-refresh"></i> reload</a>
                </div>
            </div>
        </div>

        <div v-else class="round-2 of-h">

            <tabs class="text-center c-tabs-simple" align="center">
                <tab :title="'Accepted' + '(' + acceptedCount + ')'">
                    <submitted-emd-accepted @showAttachment="showAttachments"
                                            @viewBidderDetails="viewBidderDetails"
                                            @data-count="setAcceptedCount"></submitted-emd-accepted>
                </tab>

                <tab :title="'Waiting for EMD approval' +  '(' + waitingDataCount + ')'">
                    <submitted-emd-waiting @updateCount="setWaitingDataCount"
                                           @showAttachment="showAttachments"
                                           @viewBidderDetails="viewBidderDetails"
                                           :data="auction"></submitted-emd-waiting>
                </tab>

                <tab :title="'Rejected' +  '(' + rejectedCount + ')'">
                    <submitted-emd-rejected @showAttachment="showAttachments"
                                            @viewBidderDetails="viewBidderDetails"></submitted-emd-rejected>
                </tab>
            </tabs>

        </div>
        <modal class="c-modal c-p-0 no-heading no-close" :no-close-on-backdrop="false" title=""
               ref="uploadedDocsModal" width="40r"
               header-color="">
            <table class="table table-bordered border-r-2 mb-0">
                <thead>
                <tr class="bg-4">
                    <th rowspan="1" colspan="2" class="pos-r">
                        <div class="font-inter-medium text-center fs-lg-2">
                            Uploaded Documents
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="section-items">
                <tr>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>EMD Form</span> <span>:</span></span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="document_details.emd_payment_form"
                                         @click="setDownload(document_details.emd_payment_form)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>EMD Payment Receipt</span> <span>:</span></span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="document_details.emd_payment_receipt"
                                         @click="setDownload(document_details.emd_payment_receipt)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>Proof Of Address</span> <span>:</span></span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="document_details.proof_of_address"
                                         @click="setDownload(document_details.proof_of_address)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>Pancard</span> <span>:</span></span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="document_details.pancard"
                                         @click="setDownload(document_details.pancard)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                                <span class="d-flex-w50 fl-j-sb">
                                   <span>Declaration Form</span> <span>:</span></span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                    <btn size="xs" v-if="document_details.declaration_form"
                                         @click="setDownload(document_details.declaration_form)"
                                         class="text-decoration-none" title="View">
                                    </btn>
                                    <span v-else>Not Uploaded</span>
                                </span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </modal>
        <modal class="c-modal c-p-0 no-heading no-close" :no-close-on-backdrop="false" title=""
               ref="bidderDetailsModal" width="40r"
               header-color="">
            <table class="table table-bordered border-r-2 mb-0">
                <thead>
                <tr class="bg-4">
                    <th rowspan="1" colspan="2" class="pos-r">
                        <div class="font-inter-medium text-center fs-lg-2">
                            Bidder Details
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="section-items">
                <tr>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                            <span class="d-flex-w50 fl-j-sb">
                                <span>Bidder Id</span> <span>:</span>
                            </span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                {{ bidderDetails.participant_id }}
                            </span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                            <span class="d-flex-w50 fl-j-sb">
                                <span>Bidder Name</span> <span>:</span>
                            </span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                {{ bidderDetails.name }}
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                            <span class="d-flex-w50 fl-j-sb">
                                <span>E-mail</span> <span>:</span>
                            </span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                {{ bidderDetails.email }}
                            </span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                            <span class="d-flex-w50 fl-j-sb">
                                <span>Mobile</span> <span>:</span>
                            </span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                {{ bidderDetails.mobile }}
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                            <span class="d-flex-w50 fl-j-sb">
                                <span>Account Number</span> <span>:</span>
                            </span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                {{ bidderDetails.account_number }}
                            </span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                            <span class="d-flex-w50 fl-j-sb">
                                <span>IFSC Code</span> <span>:</span>
                            </span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                {{ bidderDetails.ifsc_code }}
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div class="fl-x-lg">
                            <span class="d-flex-w fl-j-sb">
                                <span>Account Name</span> <span>:</span>
                            </span>
                            <br class="d-lg-none"/>
                            <span class="ml-2 ml-lg-3">
                                {{ bidderDetails.account_name }}
                            </span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </modal>
    </inner-page-card>
</template>

<script>
import SubmittedEmdAccepted from '../../views/manage-submitted-emd/SubmittedEmdAccepted';
import SubmittedEmdWaiting from '../../views/manage-submitted-emd/SubmittedEmdWaiting';
import SubmittedEmdRejected from '../../views/manage-submitted-emd/SubmittedEmdRejected';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'ManageSubmittedEmd',

    components : { SubmittedEmdRejected, SubmittedEmdWaiting, SubmittedEmdAccepted },

    data () {
        return {
            auction          : null,
            document_details : {
                emd_payment_form    : '',
                emd_payment_receipt : '',
                proof_of_address    : '',
                pancard             : '',
                declaration_form    : ''
            },

            bidderDetails : {},

            loading          : true,
            error            : false,
            acceptedCount    : 0,
            waitingDataCount : 0,
            rejectedCount    : 0
        };
    },

    beforeMount () {
        this.loadData();
    },
    computed : {
        counts () {
            return this.acceptedCount;
        }
    },
    methods : {
        setWaitingDataCount (count, type) {
            this.waitingDataCount = count;
            if (type === '1') {
                this.acceptedCount += 1;
            } else if (type === '2') {
                this.rejectedCount += 1;
            }
        },
        showAttachments (data) {
            this.document_details.emd_payment_form = data.emd_payment_form;
            this.document_details.emd_payment_receipt = data.emd_payment_receipt;
            this.document_details.proof_of_address = data.proof_of_address;
            this.document_details.pancard = data.pancard;
            this.document_details.declaration_form = data.declaration_form;
            this.$refs.uploadedDocsModal.show();
        },
        viewBidderDetails (data) {
            this.bidderDetails = { ...data };
            this.$refs.bidderDetailsModal.show();
        },
        setDownload (url) {
            if (url) {
                window.open(url);
            } else {
                alert('No file contains');
            }
        },

        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            const model = {
                auction : that.$route.params.id
            };

            axios.form(urls.auction.auction.info, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.auction = { ...json.data };
                    that.loading = false;
                    console.log('that.auction : ', that.auction);
                } else {
                    that.loading = false;
                    that.error = true;
                }
            }).catch(function (exception) {
                console.log('exception at the incident details loading => ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        // setAcceptedCount (acceptedCount, waitingCount, rejectedCount) {
        setAcceptedCount (counts) {
            this.acceptedCount = counts.accepted;
            this.waitingDataCount = counts.waiting;
            this.rejectedCount = counts.rejected;
        },
        setWaitingCount (count) {
            this.waitingDataCount = count;
        }
    }
};
</script>

<style scoped>

</style>
