<template>

    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details" v-bind:key="k" class="mt-4">

                <div class="gr-3-cols">

                    <div class="fl-gr-1 b-1 py-2">
                        <div class="fl-x fl-j-c fl-a-c">
                            <div class="pos-r pl-4 pr-4">
                                <div class=""></div>
                                <span class="font-inter-semi-bold">Bidder Id</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1 py-2">
                        <div class="">
                            <div class="pos-r text-center">
                                <div class=""></div>
                                <div class="font-inter-semi-bold text-center">Payment Reference Number</div>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1 py-2">
                        <div class="fl-x fl-j-s fl-a-c">
                            <div class="pos-r pl-4  pr-4">
                                <div class=""></div>
                                <span class="font-inter-semi-bold">Amount</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="b-1 bs-3">
                    <div class="gr-3-cols font-inter-medium">

                        <div class="b-1">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r fl-x-bc py-2">
                                    <div class=""></div>
                                    <btn shadow="0" class="text-decoration-none line-bottom-primary" design=""
                                         @click="viewBidderDetails(i)">{{ i.participant_id }}
                                    </btn>
                                </div>
                            </div>
                        </div>

                        <div class="b-1">
                            <div class="pos-r fl-x-bc py-2">
                                <div class=""></div>
                                {{ i.utr_number }}
                            </div>
                        </div>

                        <div class="b-1">
                            <div class="pos-r pl-2 py-2 fl-x-bc">
                                <div class=""></div>
                                {{ i.amount }}
                            </div>
                        </div>

                    </div>

                    <div class="row  fs--1 m-0">
                <span class="b-1 w-100 col-6">
                    <div class="p-1">
                       <span class="font-inter-medium">Submitted Date</span><br> <span class="ml-2">{{
                            i.submitted_date
                        }}</span>
                    </div>
                </span>
                        <span class="b-1 w-100 col-6">
                    <div class="p-1">
                       <span class="font-inter-medium">Attachment :</span><br> <span class="ml-2">
                        <btn design="" shadow="0" class="text-decoration-none line-bottom-primary" text="View"
                             @click="$emit('view-attachment',i.emd_payment_receipt)"></btn>
                    </span>
                    </div>
                </span>
                        <span class="b-1 w-100 col-6">
                    <div class="p-1">
                       <span class="font-inter-medium">Rejected Date</span> <br> <span class="ml-2">{{
                            i.rejected_date
                        }}</span>
                    </div>
                </span>
                        <span class="b-1 w-100 col-6">
                    <div class="p-1">
                       <span class="font-inter-medium">Rejected By&nbsp;:&nbsp;</span> <span
                        class="ml-lg-2 d-inline-block ml-0 d-inline-block">{{ i.rejected_by }}</span>
                    </div>
                </span>
                        <span class="b-1 w-100 col-12">
                    <div class="p-2">
                       <span class="font-inter-medium">Rejected Reason :</span> <span
                        class="ml-lg-2 d-inline-block ml-0 d-inline-block">{{ i.rejected_reason }}</span>
                    </div>
                </span>
                        <span class="b-1 w-100 col-12">
                    <div class="p-2">
                       <span class="font-inter-medium">Added By :</span>
                        <span v-if="i.is_bank_admin"
                              class="ml-lg-2 d-inline-block ml-0 d-inline-block">Bank Admin</span>
                        <span v-else class="ml-lg-2 d-inline-block ml-0 d-inline-block">Customer</span>
                    </div>
                </span>

                    </div>

                </div>
            </div>

            <div v-if="details.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
// import ActivateSuspendButtons from '@components/manage-users/ui/ActivateSuspendButtons';
import { mapGetters } from 'vuex';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'EmdRejectedMobileView',

    props : {
        district : {
            type : String
        },
        state : {
            type : String
        },
        customer_type : {
            type : String
        },
        all_search : {
            type : String
        }
    },

    watch : {
        district () {
            this.loadData();
        },

        state () {
            this.loadData();
        },

        customer_type () {
            this.loadData();
        },

        all_search () {
            this.loadData();
        }

    },

    data () {
        return {
            loading : true,
            error   : false,
            details : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.auction.participant.emdFormList + '?auction=' + this.$route.params.id + '&status=failure', {}).then(function (response) {
                console.log('participant - response', response);
                const json = response.data;
                that.details = [...json.data];
                that.loading = false;
                that.error = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },

        toggleUserActiveStatus (i) {
            this.items[i].active = !this.items[i].active;
        },

        setView (item) {
            // this.$router.push('/bidders/' + item.id + '/details/');
            this.$emit('view-attachment', item.emd_payment_receipt);
        },

        viewBidderDetails (item) {
            // this.$router.push('/bidders/' + item.id + '/details/');
            this.$emit('viewBidderDetails', item);
        }
    }
};
</script>

<style scoped lang="scss">
.gr-4-cols {
    display: grid;
    grid-template-columns:repeat(4, 1fr);
}

.gr-3-cols {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
}

.c-line-bottom {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.3);
    }
}

.icon-danger {
    i {
        color: var(--color-danger) !important;
    }
}

.icon-primary {
    i {
        color: var(--color-primary) !important;
    }
}
</style>
