<template>
    <div>
        <custom-vue-table-card @load="loadedData(rowData)" class="c-vue-table-card d-sm-none-lg-block" :fields="fields"
                               :url="listUrl" :per-page="10"
                               ref="table" :show-search-box="true">

            <template #attachments="{rowData}">
                <btn shadow="0" class="text-decoration-none line-bottom-primary" design=""
                     @click="showAttachment(rowData)">View
                </btn>
            </template>

            <template #participant_id="{rowData}">
                <btn shadow="0" class="text-decoration-none line-bottom-primary" design=""
                     @click="viewBidderDetails(rowData)">{{ rowData.participant_id }}
                </btn>
            </template>

            <template #is_bank_admin="{rowData}">
                <span v-if="rowData.is_bank_admin">Bank Admin</span>
                <span v-else>Customer</span>
            </template>

        </custom-vue-table-card>
        <emd-accepted-mobile-view @viewBidderDetails="viewBidderDetails" @data-count="emitDataCount"
                                  class="d-lg-none"></emd-accepted-mobile-view>
    </div>

</template>

<script>
import urls from '@/data/urls';
import EmdAcceptedMobileView from '../../views/manage-submitted-emd/mobile-view/EmdAcceptedMobileView';

export default {
    name       : 'SubmittedEmdAccepted',
    components : { EmdAcceptedMobileView },
    data () {
        return {
            listUrl : urls.auction.participant.emdFormList + '?auction=' + this.$route.params.id + '&status=success',

            fields : [
                {
                    name  : '__slot:participant_id',
                    title : 'Bidder ID'
                },
                {
                    name  : 'utr_number',
                    title : 'Payment Reference Number'
                },
                {
                    name  : 'amount',
                    title : 'Amount'
                },
                {
                    name  : 'submitted_date',
                    title : 'Submitted Date'
                },
                {
                    name  : '__slot:attachments',
                    title : 'Attachment'
                },
                {
                    name  : 'accepted_date',
                    title : 'Accepted Date'
                },
                {
                    name  : 'accepted_by',
                    title : 'Accepted By'
                },
                {
                    name  : 'added_by',
                    title : 'Added By'
                }
            ]
        };
    },

    methods : {

        viewBidderDetails (data) {
            this.$emit('viewBidderDetails', data);
        },

        showAttachment (data) {
            this.$emit('showAttachment', data);
        },
        loadedData (data) {
            console.log('accepted data', data);
        },
        emitDataCount (counts) {
            this.$emit('data-count', counts);
        }
    }
};
</script>

<style scoped>

</style>
